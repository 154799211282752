import React, { FC } from "react";
import {IIconProps} from "./Roof5";

const Roof2: FC<IIconProps> = ({ selected }) => {
    return (
        <svg width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg" stroke={ selected ? 'white' : 'black' }>
            <g id="Icon/1">
                <path id="Icon" d="M22.6665 20H34.6665M22.6665 20V66.6667M22.6665 20C22.6665 16.3181 19.9802 13.3334 16.6665 13.3334C13.3528 13.3334 10.6665 16.3181 10.6665 20V66.6667C10.6665 62.9848 13.3528 60 16.6665 60C19.9802 60 22.6665 62.9848 22.6665 66.6667M34.6665 20V66.6667M34.6665 20C34.6665 16.3181 37.3528 13.3334 40.6665 13.3334C43.9802 13.3334 46.6665 16.3181 46.6665 20M34.6665 66.6667H22.6665M34.6665 66.6667C34.6665 62.9848 37.3528 60 40.6665 60C43.9802 60 46.6665 62.9848 46.6665 66.6667M46.6665 20H58.6665M46.6665 20V66.6667M58.6665 20V66.6667M58.6665 20C58.6665 16.3181 61.3528 13.3334 64.6665 13.3334C67.9802 13.3334 70.6665 16.3181 70.6665 20V66.6667C70.6665 62.9848 67.9802 60 64.6665 60C61.3528 60 58.6665 62.9848 58.6665 66.6667M58.6665 66.6667H46.6665" strokeWidth="1.67" strokeLinejoin="round"/>
            </g>
        </svg>
    );
};

export default Roof2;
