import React, {FC, useState} from "react";
import styled from 'styled-components';
import tw from "twin.macro";

const StyledLabel = styled.label`${tw`flex justify-start place-items-center cursor-pointer my-4`}`
const StyledTitle = styled.span`${tw`text-2sm`}`

interface IRadioInput {
    handleOnClick?: React.MouseEventHandler
    name: string,
    title: string,
    value: string,
    defaultChecked?: boolean
}

const RadioInput: FC<IRadioInput> = ({ handleOnClick, name, title, value, defaultChecked }) => {
    return (
        <StyledLabel onClick={ handleOnClick }>
            <input value={ value } type="radio" name={ name } className="mx-2" defaultChecked={ defaultChecked } />
            <StyledTitle>{ title }</StyledTitle>
        </StyledLabel>
    );
};

export default RadioInput;
