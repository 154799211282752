import React, { FC } from "react";
import styled from 'styled-components';
import tw from "twin.macro";
import Roof1 from "./icons/Roof1";
import Roof2 from "./icons/Roof2";
import Roof3 from "./icons/Roof3";
import Roof4 from "./icons/Roof4";
import Roof5 from "./icons/Roof5";
import Ground from "./icons/Ground";

const activeStyles = 'bg-accentColor text-secondaryColor border border-solid border-accentColor';
const inactiveStyles = 'bg-secondaryColor text-primaryColor border border-solid border-tertiaryColor';

interface IContainerProps {
    $iconName: string
}

const StyledContainer = styled.div<IContainerProps>`
  ${tw`h-[8rem] cursor-pointer flex justify-start place-items-center px-4 relative`}
  background-image: url(calc-icons/${(props) => props.$iconName}.svg);
  background-repeat: no-repeat;
  background-size: 13.125rem 10.188rem;
  background-position-x: 120%;
  background-position-y: -50%;
`

const StyledTitle = styled.div`${tw`font-medium`}`

interface RoofGroundTypeBoxProps {
    title: string,
    icon: string,
    selected: boolean,
    handleOnClick?: React.MouseEventHandler
}

const RoofGroundTypeBox: FC<RoofGroundTypeBoxProps> = ({ title, icon, selected, handleOnClick }) => {

    const renderIcon = (iconType: string, selected: boolean) => {
        switch(iconType) {
            case 'roof1':
                return <Roof1 selected={ selected } />;
            case 'roof2':
                return <Roof2 selected={ selected } />;
            case 'roof3':
                return <Roof3 selected={ selected } />;
            case 'roof4':
                return <Roof4 selected={ selected } />;
            case 'roof5':
                return <Roof5 selected={ selected } />;
            case 'ground':
                return <Ground selected={ selected } />;
        }
    }

    return (
        <StyledContainer $iconName={ icon } onClick={ handleOnClick } className={ selected ? activeStyles : inactiveStyles }>
            { renderIcon(icon, selected) }
            <StyledTitle>{ title }</StyledTitle>
        </StyledContainer>
    );
};

export default RoofGroundTypeBox;
