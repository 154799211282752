import { PRICING_COLS } from "./Consts";
import { InverterType } from "../data/Inverters";
import { RoofGroundType } from "../data/RoofGround";

export const mapModulesCountToRowIndex = (modules: number) => {
    if (modules >= 7 && modules <= 105) {
        return modules - 5;
    }

    return 2;
}

export const mapEnergyStorageValueToIndex = (energyStorageValue: number) => {
    switch (energyStorageValue) {
        case 10:
            return PRICING_COLS.ENERGY_STORAGE_10;
        case 15:
            return PRICING_COLS.ENERGY_STORAGE_15;
        case 20:
            return PRICING_COLS.ENERGY_STORAGE_20;
        case 25:
            return PRICING_COLS.ENERGY_STORAGE_25;
        case 30:
            return PRICING_COLS.ENERGY_STORAGE_30;
        case 35:
            return PRICING_COLS.ENERGY_STORAGE_35;
        default:
            return PRICING_COLS.ENERGY_STORAGE_10;
    }
}

export const getPower = (modules: number, pricing: Array<any>) => {
    const pricingRow = pricing[mapModulesCountToRowIndex(modules)];

    if (typeof pricingRow === "undefined") {
        return '0,000';
    }

    return parseStringAmountToFloat(pricingRow[PRICING_COLS.POWER]).toFixed(3).replace('.',',');
}

const parseStringAmountToFloat = (amount: string) => {
    return parseFloat(amount.replace(',','.').replace(' ',''))
}

export const calculateCost = (
    inverter: InverterType,
    roofGround: RoofGroundType,
    energyStorage: number,
    cableLength: number,
    vatType: string,
    pricingRow: Array<any>
) => {
    if (typeof pricingRow === "undefined") {
        return 0.00;
    }

    let totalCost = 0.00;
    let basePrice = parseStringAmountToFloat(pricingRow[PRICING_COLS.BASE_PRICE]);
    let energyStorageCost = 0.00;
    let cableCost = 0.00;

    if (inverter.index === PRICING_COLS.INVERTER_2) {
        energyStorageCost = parseStringAmountToFloat(pricingRow[mapEnergyStorageValueToIndex(energyStorage)]);
    }

    if (roofGround.index === PRICING_COLS.GROUND_ROOF_TYPE_6) {
        cableCost = parseStringAmountToFloat(pricingRow[PRICING_COLS.CABLE]) * cableLength;
    }

    totalCost = basePrice + parseStringAmountToFloat(pricingRow[inverter.index]) + parseStringAmountToFloat(pricingRow[roofGround.index]) + energyStorageCost + cableCost;

    if (vatType === 'vat8') {
        totalCost *= 1.08;
    } else if (vatType === 'vat23') {
        totalCost *= 1.23;
    }

    return totalCost;
}
