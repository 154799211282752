import React, { FC } from "react";
import {IIconProps} from "./Roof5";

const Ground: FC<IIconProps> = ({ selected }) => {
    return (
        <svg width="81" height="80" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M7.26474 4.91542C7.30042 4.81618 7.39454 4.75 7.5 4.75H21C21.0814 4.75 21.1577 4.78962 21.2045 4.8562C21.2513 4.92279 21.2628 5.00799 21.2353 5.08458L19.75 9.21568V16.0516C19.75 16.1897 19.6381 16.3016 19.5 16.3016C19.3619 16.3016 19.25 16.1897 19.25 16.0516V10.6064L16.7353 17.6009C16.6996 17.7001 16.6055 17.7663 16.5 17.7663H14.2188V19C14.2188 19.1381 14.1068 19.25 13.9688 19.25C13.8307 19.25 13.7188 19.1381 13.7188 19V17.7663H5.78125V19C5.78125 19.1381 5.66932 19.25 5.53125 19.25C5.39318 19.25 5.28125 19.1381 5.28125 19V17.7663H3C2.91861 17.7663 2.84231 17.7267 2.7955 17.6601C2.74868 17.5935 2.73721 17.5083 2.76474 17.4317L7.26474 4.91542ZM13.4806 17.2663H16.3242L18.3945 11.5082H15.5508L13.4806 17.2663ZM15.7306 11.0082H18.5742L20.6445 5.25H17.8008L15.7306 11.0082ZM17.2695 5.25L15.1992 11.0082H12.3556L14.4258 5.25H17.2695ZM13.8945 5.25H11.0508L8.98055 11.0082H11.8242L13.8945 5.25ZM11.6445 11.5082H8.80079L6.73055 17.2663H9.57422L11.6445 11.5082ZM10.1056 17.2663H12.9492L15.0195 11.5082H12.1758L10.1056 17.2663ZM6.19922 17.2663L8.26945 11.5082H5.42579L3.35555 17.2663H6.19922ZM5.60555 11.0082H8.44922L10.5195 5.25H7.67579L5.60555 11.0082Z" fill={ selected ? 'white' : 'black' }/>
        </svg>
    );
};

export default Ground;
