import * as React from 'react';
import PageData from "../../types/PageData";
import { Helmet } from 'react-helmet'

type LayoutProps = {
    children: string | JSX.Element | JSX.Element[];
    pageData?: PageData
}

const Layout = ({children, pageData} : LayoutProps) => {
    return (
        <>
            <Helmet>
                <title>{ pageData?.siteTitle } | { pageData?.pageTitle }</title>
                { pageData?.canonicalUrl && <link rel="canonical" href={ pageData?.canonicalUrl } />}
            </Helmet>
            <div className="font-body text-baseMobile md:text-base m-auto bg-white items-center">
                <main className="p-[1rem] box-border items-center">
                    { children }
                </main>
            </div>
        </>
    );
}

export default Layout
