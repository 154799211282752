import React, { FC } from "react";
import {IIconProps} from "./Roof5";

const Roof4: FC<IIconProps> = ({ selected }) => {
    return (
        <svg width="80" height="81" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M11.9167 4.25C11.1996 4.25 10.6183 4.83131 10.6183 5.54839C10.6183 5.88109 10.7431 6.18404 10.9488 6.41392C11.0146 6.48739 11.031 6.59265 10.9908 6.68267C10.9505 6.77269 10.8611 6.83065 10.7625 6.83065H7.49429C6.5987 6.83065 5.83706 7.48407 5.70088 8.36925L4.2716 17.6596C4.1025 18.7587 4.95292 19.75 6.06501 19.75H17.7683C18.8804 19.75 19.7308 18.7587 19.5617 17.6596L18.1324 8.36925C17.9963 7.48407 17.2346 6.83065 16.339 6.83065H13.0708C12.9722 6.83065 12.8828 6.77269 12.8425 6.68267C12.8023 6.59265 12.8187 6.48739 12.8845 6.41392C13.0902 6.18404 13.215 5.88109 13.215 5.54839C13.215 4.83131 12.6337 4.25 11.9167 4.25ZM10.1183 5.54839C10.1183 4.55517 10.9234 3.75 11.9167 3.75C12.9099 3.75 13.715 4.55517 13.715 5.54839C13.715 5.82863 13.6508 6.09409 13.5364 6.33065H16.339C17.4814 6.33065 18.4529 7.16413 18.6266 8.29322L20.0559 17.5835C20.2716 18.9856 19.1868 20.25 17.7683 20.25H6.06501C4.64648 20.25 3.56171 18.9856 3.77741 17.5835L5.20669 8.29322C5.3804 7.16413 6.35192 6.33065 7.49429 6.33065H10.2969C10.1825 6.09409 10.1183 5.82863 10.1183 5.54839ZM11.9166 5.28226C11.7697 5.28226 11.6505 5.40141 11.6505 5.54839C11.6505 5.69537 11.7697 5.81452 11.9166 5.81452C12.0636 5.81452 12.1828 5.69537 12.1828 5.54839C12.1828 5.40141 12.0636 5.28226 11.9166 5.28226ZM11.1505 5.54839C11.1505 5.12527 11.4935 4.78226 11.9166 4.78226C12.3398 4.78226 12.6828 5.12527 12.6828 5.54839C12.6828 5.97151 12.3398 6.31452 11.9166 6.31452C11.4935 6.31452 11.1505 5.97151 11.1505 5.54839Z" fill={ selected ? 'white' : 'black' } />
            <path d="M13.8709 16.2499C13.5649 16.2499 13.2716 16.2053 12.991 16.116C12.7105 16.0267 12.4831 15.8992 12.3088 15.7334L12.5383 15.3891C12.6956 15.5294 12.889 15.6399 13.1186 15.7207C13.3523 15.8057 13.5989 15.8482 13.8582 15.8482C14.2833 15.8482 14.5957 15.7483 14.7955 15.5485C14.9953 15.353 15.0952 15.0469 15.0952 14.6303V13.7951L15.159 13.2212L15.1143 12.6473V11.6335H15.5479V14.573C15.5479 15.1511 15.4055 15.574 15.1207 15.8418C14.8401 16.1139 14.4236 16.2499 13.8709 16.2499ZM13.7881 14.8535C13.4692 14.8535 13.1823 14.7855 12.9273 14.6495C12.6722 14.5092 12.4703 14.3158 12.3215 14.0692C12.177 13.8227 12.1047 13.54 12.1047 13.2212C12.1047 12.9024 12.177 12.6218 12.3215 12.3795C12.4703 12.133 12.6722 11.9417 12.9273 11.8057C13.1823 11.6696 13.4692 11.6016 13.7881 11.6016C14.0856 11.6016 14.3534 11.6633 14.5915 11.7865C14.8295 11.9098 15.0187 12.0926 15.159 12.3349C15.2992 12.5772 15.3694 12.8726 15.3694 13.2212C15.3694 13.5698 15.2992 13.8652 15.159 14.1075C15.0187 14.3498 14.8295 14.5347 14.5915 14.6622C14.3534 14.7898 14.0856 14.8535 13.7881 14.8535ZM13.8327 14.4518C14.0792 14.4518 14.2982 14.4008 14.4894 14.2988C14.6807 14.1925 14.8316 14.048 14.9422 13.8652C15.0527 13.6782 15.1079 13.4635 15.1079 13.2212C15.1079 12.9789 15.0527 12.7664 14.9422 12.5836C14.8316 12.4008 14.6807 12.2584 14.4894 12.1564C14.2982 12.0501 14.0792 11.997 13.8327 11.997C13.5904 11.997 13.3715 12.0501 13.1759 12.1564C12.9847 12.2584 12.8337 12.4008 12.7232 12.5836C12.617 12.7664 12.5638 12.9789 12.5638 13.2212C12.5638 13.4635 12.617 13.6782 12.7232 13.8652C12.8337 14.048 12.9847 14.1925 13.1759 14.2988C13.3715 14.4008 13.5904 14.4518 13.8327 14.4518Z" fill={ selected ? 'white' : 'black' } />
            <path d="M9.10712 14.114L9.11988 13.5337L11.2049 11.6336H11.7596L10.2867 13.0427L10.0317 13.2595L9.10712 14.114ZM8.75005 14.9811V10.2499H9.20277V14.9811H8.75005ZM11.3452 14.9811L9.94879 13.2404L10.2421 12.8897L11.8999 14.9811H11.3452Z" fill={ selected ? 'white' : 'black' } />
        </svg>
    );
};

export default Roof4;
