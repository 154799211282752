import React, {FC} from 'react';
import { Page, Text, View, Document, StyleSheet, Font, Svg } from '@react-pdf/renderer';
import { InverterType } from "../data/Inverters";
import { RoofGroundType } from "../data/RoofGround";
import { PRICING_COLS } from "../utils/Consts";
import PdfCalculationSection from "./pdf/PdfCalculationSection";
import { getLogo } from "./pdf/Icons";

Font.register({
    family: "Montserrat",
    fonts: [
        { src: `${process.env.PUBLIC_URL}/fonts/Montserrat-VariableFont_wght.ttf` },
        { src: `${process.env.PUBLIC_URL}/fonts/Montserrat-Bold.ttf` }
    ]
});

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#fff',
        fontFamily: "Montserrat"
    },
    content: {
        borderLeft: "4pt black solid",
        borderColor: "#31A1A3",
        borderStyle: "dashed",
        marginLeft: "45pt"
    },
    contentInner: {
        position: "relative",
        left: "-50pt",
        width: "650pt"
    },
    title: {
        fontWeight: "bold",
        fontSize: 12,
        padding: 10,
        marginTop: 10,
        marginLeft: 10,
        color: "#31A1A3"
    },
    header: {
        flexDirection: 'row',
        justifyContent: "space-between"
    },
    haderLogo: {
        width: 94,
        height: 51
    },
    headerRight: {
        padding: 10,
        marginTop: 10,
        marginRight: 10,
        flexDirection: "row"
    },
    headerTextTitle: {
        fontWeight: "bold"
    },
    headerText: {
        alignItems: "flex-end",
        fontSize: 6,
        marginRight: 30
    },
    footer: {
        fontSize: 8,
        textAlign: "center",
        fontWeight: "bold",
        color: "#31A1A3",
        position: "absolute",
        bottom: 20,
        width: "100%"
    }
});

interface IPdfDocumentProps {
    power: string;
    modules: number;
    inverter: InverterType;
    energyStorage: number;
    roofGround: RoofGroundType;
    cableLength: number;
    vatType: string;
    totalCost: string;
}

const PdfCalculationDocument: FC<IPdfDocumentProps> = ({ power, modules, inverter, energyStorage, roofGround, cableLength, vatType, totalCost }) => (
    <Document>
        <Page size="A4" style={styles.page}>
            <View style={styles.header}>
                <View style={styles.title}>
                    <Text>KALKULACJA</Text>
                    <Text>instalacja fotowoltaiczna</Text>
                </View>
                <View style={styles.headerRight}>
                    <View style={styles.headerText}>
                        <Text style={styles.headerTextTitle}>ERATO ENERGY S.A. DOMANIEWSKA 45, 02-672 WARSZAWA.</Text>
                        <Text>Siedziba spółki: Fiołkowa 3, 52-200 Wysoka</Text>
                        <Text>NIP: 8971742783 REGON: 020441378 KRS: 0000347674</Text>
                        <Text>Sąd Rejonowy dla Wroclawia-Fabrycznej we Wrocławiu</Text>
                        <Text>VI Wydział Gospodarczy Krajowego Rejestru Sądowego.</Text>
                        <Text>Kapitał zakładowy: 3.497.640,98 zł (w pełni oplacony).</Text>
                    </View>
                    <Svg style={styles.haderLogo} viewBox="0 0 94 51">{ getLogo() }</Svg>
                </View>
            </View>
            <View style={styles.content}>
                <View style={styles.contentInner}>
                    <PdfCalculationSection title="Moc" value={ `${ power } kWp` } icon={ 1 } />

                    <PdfCalculationSection title="Ilość modułów" value={ modules } icon={ 2 } />

                    <PdfCalculationSection title="Falownik" value={ inverter.title } icon={ 3 } />

                    {
                        inverter.index === PRICING_COLS.INVERTER_2 && <PdfCalculationSection title="Magazyn energii" value={ `${ energyStorage } kWh` } icon={ 4 } />
                    }

                    <PdfCalculationSection title="Rodzaj pokrycia dachu/grunt" value={ roofGround.title } icon={ 5 } />

                    {
                        roofGround.index === PRICING_COLS.GROUND_ROOF_TYPE_6 && <PdfCalculationSection title="Kabel (mb)" value={ cableLength } icon={ 6 } />
                    }

                    <PdfCalculationSection title="Podatek VAT" value={ `${ vatType === 'vat8' ? 8 : 23 }%` } icon={ 7 } />

                    <PdfCalculationSection title="Koszt całkowity" summary={ true } value={ totalCost } icon={ 8 } />
                </View>
            </View>
            <View style={styles.footer}>
                <Text>INSTALACJE FOTOWOLTAICZNE I POMPY CIEPŁA I MAGAZYNY ENERGII I ELEKTROWNIE WIATROWE</Text>
            </View>
        </Page>
    </Document>
);

export default PdfCalculationDocument;
