import * as Yup from "yup";

export const initialValues = {
    customerName: "",
    customerEmail: "",
    phoneNumber: "",
    zipCode: "",
    acceptPrivacyPolicy: false
};

export const validationSchema = Yup.object({
    customerName: Yup.string().min(5, 'Wpisz poprawną wartość.').required('Pole wymagane'),
    customerEmail: Yup.string().email('Wpisz poprawny adres email').required('Pole wymagane'),
    phoneNumber: Yup.string().min(9, 'Wpisz poprawną wartość.').required('Pole wymagane'),
    zipCode: Yup.string().min(3, 'Wpisz poprawną wartość.').required('Pole wymagane'),
    acceptPrivacyPolicy: Yup.bool().oneOf([true], 'Zgoda jest wymagana.').required('Pole wymagane')
});
