import React from "react";

interface IProps {
    price: number;
}

export const formatPrice = (price: number) => {
    return Intl.NumberFormat('pl-PL', {
        style: 'currency',
        currency: 'PLN' // Change this
    }).format(price);
}

const Currency: React.FC<IProps> = ({ price }) => {
    return (
        <>{ formatPrice(price) }</>
    );
}

export default Currency;
