import { PRICING_COLS } from "../utils/Consts";

export type InverterType = {
    index: number;
    title: string;
}

export const invertersCollection = [
    {
        index: PRICING_COLS.INVERTER_1,
        title: 'DEYE'
    },
    {
        index: PRICING_COLS.INVERTER_2,
        title: 'DEYE Hybrydowy'
    },
    {
        index: PRICING_COLS.INVERTER_3,
        title: 'Solar Edge'
    },
    {
        index: PRICING_COLS.INVERTER_4,
        title: 'Hoymiles'
    }
];
