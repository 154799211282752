export const PRICING_COLS = {
    POWER: 0,
    MODULES: 1,
    BASE_PRICE: 2,
    INVERTER_1: 4,
    INVERTER_2: 5,
    INVERTER_3: 6,
    INVERTER_4: 7,
    GROUND_ROOF_TYPE_1: 9, // Dach skośny, blacha trapezowa
    GROUND_ROOF_TYPE_2: 10, // Dach skośny, blachodachówka
    GROUND_ROOF_TYPE_3: 11, // Dach skośny, dachówka / rąbek
    GROUND_ROOF_TYPE_4: 13, // Dach płaski, system balastowy
    GROUND_ROOF_TYPE_5: 14, // Dach płaski, system klejony
    GROUND_ROOF_TYPE_6: 12, // Konstrukcja gruntowa
    ENERGY_STORAGE_10: 16,
    ENERGY_STORAGE_15: 17,
    ENERGY_STORAGE_20: 18,
    ENERGY_STORAGE_25: 19,
    ENERGY_STORAGE_30: 20,
    ENERGY_STORAGE_35: 21,
    CABLE: 23
}
