import { PRICING_COLS } from "../utils/Consts";

export type RoofGroundType = {
    index: number;
    icon: string;
    title: string;
}

export const roofGroundCollection = [
    {
        index: PRICING_COLS.GROUND_ROOF_TYPE_1,
        icon: 'roof1',
        title: 'Dach skośny, blacha trapezowa'
    },
    {
        index: PRICING_COLS.GROUND_ROOF_TYPE_2,
        icon: 'roof2',
        title: 'Dach skośny, blachodachówka'
    },
    {
        index: PRICING_COLS.GROUND_ROOF_TYPE_3,
        icon: 'roof3',
        title: 'Dach skośny, dachówka / rąbek'
    },
    {
        index: PRICING_COLS.GROUND_ROOF_TYPE_4,
        icon: 'roof4',
        title: 'Dach płaski, system balastowy'
    },
    {
        index: PRICING_COLS.GROUND_ROOF_TYPE_5,
        icon: 'roof5',
        title: 'Dach płaski, system klejony'
    },
    {
        index: PRICING_COLS.GROUND_ROOF_TYPE_6,
        icon: 'ground',
        title: 'Konstrukcja gruntowa'
    }
];
