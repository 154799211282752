import React, {FC, useState} from "react";
import styled from 'styled-components';
import tw from "twin.macro";

const StyledInputContainer = styled.div``

const StyledTitle = styled.p`${tw`text-sm`}`

const StyledUnit = styled.span`
  ${tw`text-mute text-inputColorPlaceholder`}
  position: absolute;
  top: 4px;
  right: 10px;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
  display: flex;
`

const StyledInput = styled.input`
  ${tw`w-full box-border p-3 font-body text-xs text-inputColorTextFocus border border-solid border-inputBorderColorFocus bg-inputBackgroundColorFocus rounded-[4px]`}
`

interface NumberInputProps {
    handleOnChange: React.ChangeEventHandler,
    title: string,
    unit: string,
    value: number
}

const NumberInput: FC<NumberInputProps> = ({ handleOnChange, title, unit, value }) => {
    return (
        <StyledInputContainer>
            <StyledTitle>{ title }</StyledTitle>
            <label className="relative">
                <StyledInput onChange={ handleOnChange } placeholder="30" type="text" value={ value }/>
                <StyledUnit>{ unit }</StyledUnit>
            </label>
        </StyledInputContainer>
    );
};

export default NumberInput;
