import React, { FC } from "react";
import {IIconProps} from "./Roof5";

const Roof1: FC<IIconProps> = ({ selected }) => {
    return (
        <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg" stroke={ selected ? 'white' : 'black' }>
            <g id="Icon/2">
                <path id="Icon" d="M32.9787 18.7266V66.6667M32.9787 18.7266H47.0213M32.9787 18.7266L27.234 13.3334M32.9787 66.6667H47.0213M32.9787 66.6667L27.234 61.2734M47.0213 18.7266V66.6667M47.0213 18.7266L52.766 13.3334M47.0213 66.6667L52.766 61.2734M15.7447 61.2734V18.7266V13.3334M15.7447 61.2734L10 66.6667V18.7266L15.7447 13.3334M15.7447 61.2734H27.234M27.234 61.2734V18.7266V13.3334M52.766 61.2734V18.7266V13.3334M52.766 61.2734H64.2553M64.2553 61.2734V18.7266V13.3334M64.2553 61.2734L70 66.6667V18.7266L64.2553 13.3334M52.766 13.3334H64.2553M15.7447 13.3334H27.234" strokeWidth="1.67" strokeLinejoin="round"/>
            </g>
        </svg>
    );
};

export default Roof1;
