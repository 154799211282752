import React, { FC } from "react";
import {IIconProps} from "./Roof5";

const Roof3: FC<IIconProps> = ({ selected }) => {
    return (
        <svg width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg" stroke={ selected ? 'white' : 'black' }>
            <g id="Icon/3">
                <path id="Icon" d="M30.3333 14.9254V31.6419M30.3333 14.9254C30.3333 14.9254 36.5833 11.3434 40.3333 14.9255C44.0833 18.5075 50.3332 14.9254 50.3332 14.9254M30.3333 14.9254C30.3333 14.9254 24.0833 18.5075 20.3333 14.9254C16.5833 11.3434 10.3333 14.9254 10.3333 14.9254V31.6419M30.3333 31.6419V48.3583M30.3333 31.6419C30.3333 31.6419 24.0833 35.2239 20.3333 31.6419C16.5833 28.0598 10.3333 31.6419 10.3333 31.6419M30.3333 31.6419C30.3333 31.6419 36.5833 28.0598 40.3333 31.6419C44.0833 35.2239 50.3332 31.6419 50.3332 31.6419M10.3333 31.6419V48.3583M30.3333 48.3583V65.0747M30.3333 48.3583C30.3333 48.3583 24.0833 51.9404 20.3333 48.3583C16.5833 44.7762 10.3333 48.3583 10.3333 48.3583M30.3333 48.3583C30.3333 48.3583 36.5832 44.7762 40.3332 48.3583C44.0832 51.9404 50.3332 48.3583 50.3332 48.3583M10.3333 48.3583V65.0747C10.3333 65.0747 16.5833 61.4926 20.3333 65.0747C24.0833 68.6568 30.3333 65.0747 30.3333 65.0747M30.3333 65.0747C30.3333 65.0747 36.5832 61.4926 40.3332 65.0747C44.0832 68.6567 50.3332 65.0747 50.3332 65.0747M50.3332 14.9254V31.6419M50.3332 14.9254C50.3332 14.9254 56.5833 11.3433 60.3333 14.9254C64.0833 18.5075 70.3333 14.9254 70.3333 14.9254V31.6419M50.3332 31.6419V48.3583M50.3332 31.6419C50.3332 31.6419 56.5833 28.0598 60.3333 31.6419C64.0833 35.2239 70.3333 31.6419 70.3333 31.6419M50.3332 48.3583V65.0747M50.3332 48.3583C50.3332 48.3583 56.5833 44.7762 60.3333 48.3583C64.0833 51.9404 70.3333 48.3583 70.3333 48.3583M50.3332 65.0747C50.3332 65.0747 56.5833 61.4926 60.3333 65.0747C64.0833 68.6567 70.3333 65.0747 70.3333 65.0747V48.3583M70.3333 31.6419V48.3583" strokeWidth="1.67" strokeLinejoin="round"/>
            </g>
        </svg>
    );
};

export default Roof3;
