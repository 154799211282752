export type SliderPointType = {
    value: number;
    hideOnMobile?: string;
}

export const modulesSliderStepsCollection = [
    { value: 7 },
    { value: 14, hideOnMobile: true },
    { value: 21 },
    { value: 28, hideOnMobile: true },
    { value: 35 },
    { value: 42, hideOnMobile: true },
    { value: 49 },
    { value: 56, hideOnMobile: true },
    { value: 63 },
    { value: 70, hideOnMobile: true },
    { value: 77 },
    { value: 84, hideOnMobile: true },
    { value: 91 },
    { value: 98, hideOnMobile: true },
    { value: 105 }
];

export const energyStorageSliderStepsCollection = [
    { value: 10 },
    { value: 15 },
    { value: 20 },
    { value: 25 },
    { value: 30 },
    { value: 35 }
];
