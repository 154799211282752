import styled from "styled-components";
import tw from "twin.macro";

export const Overlay = styled.div`
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 60;
    ${tw`fixed block top-0 left-0 right-0 bottom-0 w-full h-full`}
`

export const Modal = styled.div`${tw`box-border rounded fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 bg-white w-[95%] xl:w-[912px] z-[100] bg-white flex flex-col justify-between max-h-[95%] h-auto pb-8 lg:pb-20 pt-4 lg:pt-8 px-4 lg:px-16`}`
export const ModalBody = styled.div`${tw`flex flex-col overflow-auto`}`
export const ModalHeader = styled.div`${tw`flex flex-row items-center justify-between mb-8`}`
export const ModalBodySection = styled.div`${tw`flex flex-col lg:flex-row gap-6 md:gap-4 mb-6 md:mb-8`}`
