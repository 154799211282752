import React, { FC, useState } from "react";
import styled from 'styled-components';
import tw from "twin.macro";
import BrushIcon from "./icons/Brush";
import { SliderPointType } from "../data/SliderSteps";

const activeStyles = `bg-black border border-solid border-black z-10`
const inactiveStyles = `bg-white border border-solid border-[#acacac] z-10`

const StyledSliderPoint = styled.div`
    display: flex;
    justify-content: center;
    width: 12px;
    height: 12px;
    line-height: 40px;
    border-radius: 25px;
    cursor: pointer;
`

const StyledSliderPointValue = styled.span`${tw`text-2sm`}
    margin-top: 1rem;
    height: 27px;
    color: #585858;
    font-weight: 400;
    font-style: normal;
`

interface SliderPointProps {
    sliderPoint: SliderPointType,
    currentValue: number,
    handleOnClick?: React.MouseEventHandler
}

const SliderPoint: FC<SliderPointProps> = ({ sliderPoint, currentValue, handleOnClick }) => {
    return (
        <StyledSliderPoint onClick={ handleOnClick } className={ `${sliderPoint.value <= currentValue ? activeStyles : inactiveStyles} ${sliderPoint.hideOnMobile ? 'invisible md:visible' : '' }` }>
            <StyledSliderPointValue>{ sliderPoint.value }</StyledSliderPointValue>
        </StyledSliderPoint>
    );
};

export default SliderPoint;
