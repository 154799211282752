import React, { FC } from "react";
import { Field, ErrorMessage } from "formik";
import tw from 'twin.macro'
import styled from 'styled-components'
import RenderError from "./Error";

const StyledContainer = styled.div`
  ${tw`w-full flex flex-col mb-8`}
  p.error {
    color: #FF1400;
    font-size: 12px;
    line-height: 10px;
    font-weight: 400;
    padding-left: 30px;
  }
`

const StyledLabel = styled.label`${tw`flex flex-row items-start gap-2`}`

type CheckboxInputProps = {
  name: string;
  errors?: any;
  touched?: any;
}

const CheckboxInput: FC<CheckboxInputProps> = ({ name, errors, touched }) => {
  return (
      <StyledContainer>
          <StyledLabel>
              <Field className="mt-[5px] w-[17px] h-[17px]" name={ name } type="checkbox"/>
              <div className="text-2sm text-tertiaryColor">
                  Zapoznałam/łem się i wyrażam zgodę na przetwarzanie danych zgodnie z <a className="no-underline text-black" href="https://www.eratoenergy.pl/polityka-prywatnosci/" target="_blank">polityką prywatności</a>
              </div>
          </StyledLabel>
          <ErrorMessage name={ name } render={ RenderError } />
      </StyledContainer>
  );
}

export default CheckboxInput;
