import React, { FC } from "react";

export interface IIconProps {
    selected: boolean
}

const Roof5: FC<IIconProps> = ({ selected }) => {
    return (
        <svg width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg" stroke={ selected ? 'white' : 'black' }>
            <path d="M33.9533 20.4771L34.7915 19.6389C35.7173 18.7131 37.2184 18.7131 38.1443 19.6389L44.8498 26.3445L56.9347 11.574C58.5423 9.6092 61.4949 9.46193 63.29 11.257C65.0851 13.0521 64.9378 16.0047 62.973 17.6123L48.2025 29.6972L54.9081 36.4027C55.8339 37.3286 55.8339 38.8296 54.9081 39.7555L54.0699 40.5937M33.9533 20.4771L54.0699 40.5937M33.9533 20.4771L32.2769 22.1535M54.0699 40.5937L52.3935 42.2701M32.2769 22.1535L52.3935 42.2701M32.2769 22.1535L21.1769 33.2535M52.3935 42.2701L44.4307 50.2329M21.1769 33.2535L18.0277 36.4027C17.1019 37.3286 17.1019 38.8296 18.0277 39.7555L20.5423 42.2701L25.5714 38.9173L22.2187 43.9464L28.086 49.8138L36.0488 44.3655L30.6006 52.3283L34.7915 56.5193C35.7173 57.4451 37.2184 57.4451 38.1443 56.5193L44.4307 50.2329M21.1769 33.2535C26.8129 34.6949 39.3539 40.1088 44.4307 50.2329M61.1945 15.8671C60.5001 16.5615 59.3743 16.5615 58.6799 15.8671C57.9855 15.1727 57.9855 14.0469 58.6799 13.3525C59.3743 12.6581 60.5001 12.6581 61.1945 13.3525C61.8889 14.0469 61.8889 15.1727 61.1945 15.8671ZM35.3667 60.5171L32.7456 65.7592C31.7714 67.7077 33.1882 70.0002 35.3667 70.0002C37.5451 70.0002 38.9619 67.7077 37.9877 65.7592L35.3667 60.5171Z" strokeWidth="1.5" strokeLinejoin="round"/>
        </svg>
    );
};

export default Roof5;
