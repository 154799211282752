import React, { FC } from "react";
import styled from 'styled-components';
import tw from "twin.macro";

const activeStyles = 'bg-accentColor text-secondaryColor border border-solid border-accentColor';
const inactiveStyles = 'bg-secondaryColor text-primaryColor border border-solid border-tertiaryColor';

const StyledInverterContainer = styled.div`${tw`h-[8rem] cursor-pointer flex justify-center place-items-center`}`
const StyledTitle = styled.span`${tw`font-medium text-center`}`

interface InverterBoxProps {
    title: string,
    selected: boolean,
    handleOnClick?: React.MouseEventHandler
}

const InverterBox: FC<InverterBoxProps> = ({ title, selected, handleOnClick }) => {
    return (
        <StyledInverterContainer onClick={ handleOnClick } className={ selected ? activeStyles : inactiveStyles }>
            <StyledTitle>{ title }</StyledTitle>
        </StyledInverterContainer>
    );
};

export default InverterBox;
