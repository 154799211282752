import React, { FC } from "react";
import styled from 'styled-components';
import tw from "twin.macro";

const SuccessContainer = styled.div`${tw`border-suscessColor border-solid border-2 bg-suscessLightColor text-suscessColor rounded p-2 mb-8 text-sm`}`

type SuccessType = {
    successMessage: string;
}

const RequestSuccess: FC<SuccessType> = ({ successMessage }) => {
  return <SuccessContainer><span>{ successMessage }</span></SuccessContainer>;
};

export default RequestSuccess;
