import React, {FC, useState} from 'react'
import styled from "styled-components";
import tw from "twin.macro";
import TextInput from "./inputs/Text";
import CheckboxInput from "./inputs/CheckboxInput";
import Button from "./Button";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { InverterType } from "../data/Inverters";
import { RoofGroundType } from "../data/RoofGround";
import { validationSchema, initialValues } from "./form/ContactValidationSchema";
import { Modal, ModalBody, ModalBodySection, ModalHeader, Overlay } from "./modal/Styles";
import { sendContactRequest } from "../api/Contact";
import RequestError from "./RequestError";
import {PRICING_COLS} from "../utils/Consts";
import RequestSuccess from "./RequestSuccess";

interface IOfferValuesProps {
    handleOnClick?: React.MouseEventHandler;
    power: string;
    modules: number;
    inverter: InverterType;
    energyStorage: number;
    roofGround: RoofGroundType;
    cableLength: number;
    vatType: string;
    totalCost: string;
}

const ContactModal: FC<IOfferValuesProps> = ({ handleOnClick, power, modules, inverter, energyStorage, roofGround, cableLength, vatType, totalCost }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [requestSuccess, setRequestSuccess] = useState<boolean>(false);
    const [requestError, setRequestError] = useState<string|null>(null);

    const submitForm = async (values: any) => {
        setRequestError(null);

        const requestData = {
            ...values,
            power: power,
            modules: modules,
            inverter: inverter,
            energyStorage: energyStorage,
            roofGround: roofGround,
            cableLength: cableLength,
            vatType: vatType,
            totalCost: totalCost,
            includeEnergyStorage: inverter.index === PRICING_COLS.INVERTER_2,
            includeCableLength: roofGround.index === PRICING_COLS.GROUND_ROOF_TYPE_6
        }

        setLoading(true);

        sendContactRequest(requestData).then((response) => {
            setLoading(false);
            setRequestSuccess(true);
        }).catch(error => {
            setRequestError('Błąd podczas wysyłania formularza. Prosimy spróbować ponownie później.');
            setRequestSuccess(false);
            setLoading(false);
        });
    }

    return (
        <>
            <Overlay />
            <Modal>
                <ModalBody>
                    <ModalHeader>
                        <p className="text-lg font-bold m-0">Prośba o kontakt w sprawie oferty</p>
                        <img onClick={ handleOnClick } className="border border-solid border-[1px] rounded cursor-pointer" src="calc-icons/xmark.svg" />
                    </ModalHeader>
                    { requestError !== null && <RequestError errorMessage={ requestError } /> }
                    { !requestSuccess &&
                        <Formik
                            validationSchema={ validationSchema }
                            initialValues={ initialValues }
                            onSubmit={ (values, { resetForm }) => submitForm(values) }>
                            {({ values, errors, touched }) => (
                                <Form className="w-full">
                                    <ModalBodySection>
                                        <TextInput touched={ touched.customerName } errors={ errors.customerName } name="customerName" placeholder="Imię i nazwisko" />
                                        <TextInput touched={ touched.customerEmail } errors={ errors.customerEmail } name="customerEmail" placeholder="E-mail" />
                                    </ModalBodySection>
                                    <ModalBodySection>
                                        <TextInput touched={ touched.phoneNumber } errors={ errors.phoneNumber } name="phoneNumber" placeholder="Numer telefonu" />
                                        <TextInput touched={ touched.zipCode } errors={ errors.zipCode } name="zipCode" placeholder="Kod pocztowy" />
                                    </ModalBodySection>
                                    <CheckboxInput touched={ touched.acceptPrivacyPolicy } errors={ errors.acceptPrivacyPolicy } name="acceptPrivacyPolicy" />
                                    <div className="w-full flex justify-center">
                                        <Button disabled={ loading } label={ loading ? 'Wysyłanie...' : 'Wyślij wiadomość' } />
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    }
                    { requestSuccess &&
                        <RequestSuccess successMessage="Prośba o kontakt została wysłana." />

                    }
                </ModalBody>
            </Modal>
        </>
    )
}

export default ContactModal
