import React, {FC, useState} from "react";
import styled from 'styled-components';
import tw from "twin.macro";
import { ErrorMessage, Field } from "formik";
import RenderError from "./Error";

const activeStyles = 'text-inputColorTextFocus border-inputBorderColorFocus bg-inputBackgroundColorFocus';
const inactiveStyles = 'text-inputColorText border-inputBorderColor bg-inputBackgroundColor';

const StyledInputContainer = styled.div`
  ${tw`flex flex-col w-full`}
  p.error {
    color: #FF1400;
    font-size: 12px;
    line-height: 10px;
    font-weight: 400;
    padding-left: 15px;
  }
`

const StyledInput = styled.input`
  ${tw`w-full box-border p-3 font-body text-xs border border-solid rounded-[4px]`}
`

interface TextInputProps {
    name: string;
    handleOnChange?: React.ChangeEventHandler;
    placeholder?: string;
    errors?: any;
    touched?: any;
}

const TextInput: FC<TextInputProps> = ({ name, handleOnChange, placeholder, errors, touched }) => {
    const [active, setActive] = useState(false);

    const onChange = (event: any) => {
        if (typeof handleOnChange !== "undefined") {
            handleOnChange(event);
        }
    }

    const onBlurInput = (event: any) => {
        if (event.target.value === '') {
            setActive(false);
        }
    }

    return (
        <StyledInputContainer>
            <Field
                name={ name }
                type="text"
                onBlur={ (event: any) => onBlurInput(event) }
                onFocus={ () => setActive(true) }
                className={ `outline-none w-full box-border p-3 font-body text-xs border border-solid rounded-[4px] ${ active ? activeStyles : inactiveStyles }` }
                placeholder={ placeholder }
            />
            <ErrorMessage name={ name } render={ RenderError } />
        </StyledInputContainer>

    );
};

export default TextInput;
