import React, {FC, useEffect, useState} from "react";
import styled from 'styled-components';
import tw from "twin.macro";
import BrushIcon from "./icons/Brush";
import SliderPoint from "./SliderPoint";
import {SliderPointType} from "../data/SliderSteps";

const StyledTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  color: #0A0A0A;
`

const StyledTitleValue = styled.div`${tw`text-md`}
  font-style: normal;
  font-weight: 700;
  color: #000;
`

const StyledSliderContainer = styled.div`
  .rangeInput { margin: 0px; }
  .range-slider { flex: 1; }

  .slider-container {
    position: relative;
    top: 13px;
    z-index: 10;
  }
  
  .sliderpoints {
    display: flex;
    justify-content: space-between;
    padding: 0px 5px;
  }

  input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    cursor: pointer;
    outline: none;
    border-radius: 15px;
    height: 2px;
    background: #ccc;
    z-index: 5;
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: 39px;
    width: 24px;
    background-color: transparent;
    z-index: 15;
    transform: translateY(-29px);
    background-image: url("/calc-icons/slider.svg");
    background-size: contain;
    box-shadow: none;
  }

  input[type="range"]::-moz-range-thumb {
    height: 39px;
    width: 24px;
    background-color: transparent;
    border: none;
    z-index: 15;
    line-height: 40px;
    transform: translateY(-29px);
    background-image: url("/calc-icons/slider.svg");
    background-size: contain;
  }
  
  .range {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 0 auto;
    height: 6rem;
    background: #fff;
    padding: 0px 0px;
  }
`

interface IStep {
    value: number
}

interface ISlider {
    handleOnChange: Function,
    identifier: string,
    title: string,
    valueTitle: string,
    min: number,
    max: number,
    value: number,
    step: number,
    steps: Array<IStep>
}

const Slider: FC<ISlider> = ({ handleOnChange, identifier, title, valueTitle, min, max, value, step, steps }) => {

    useEffect(() => {
        const sliderEl = document.querySelector('#' + identifier);

        if (sliderEl !== null) {
            sliderEl.dispatchEvent(new Event('input', { bubbles: true }));
        }
    });

    const [sliderValue, setSliderValue] = useState(value);

    const onPointClick = (pointValue: number) => {
        setSliderValue(pointValue);
        handleOnChange(pointValue);
    }

    const updateValue = (event: any) => {
        setSliderValue(event.target.value);
        const tempSliderValue = event.target.value;
        const points = event.target.max - event.target.min;
        const current = tempSliderValue - event.target.min;
        const progress = (100 / points) * current;

        if (event.target.min === event.target.value) {
            event.target.style.background = `linear-gradient(to right, #000 5px, #acacac 5px)`;
        } else {
            event.target.style.background = `linear-gradient(to right, #000 ${progress}%, #acacac ${progress}%)`;
        }
    }

    return (
        <StyledSliderContainer>
            <div className="flex justify-between">
                <StyledTitle>{ title }</StyledTitle>
                <StyledTitleValue className="value">{ sliderValue } { valueTitle }</StyledTitleValue>
            </div>
            <div className="range">
                <div className="range-slider">
                    <div className="slider-container">
                        <input
                            onChange={ (event) => handleOnChange(event.target.value) }
                            onInput={ updateValue }
                            type="range"
                            min={ min }
                            max={ max }
                            value={ sliderValue }
                            id={ identifier }
                            step={ step }
                            className="rangeInput"
                        />
                    </div>
                    <div className="sliderpoints">
                        {
                            steps.map((step: SliderPointType) => <SliderPoint key={ identifier + '-point-' + step.value } handleOnClick={ () => onPointClick(step.value) } sliderPoint={ step } currentValue={ sliderValue } /> )
                        }
                    </div>
                </div>
            </div>
            <div className="clear-both w-full"></div>
        </StyledSliderContainer>
    );
};

export default Slider;
