import React, {FC} from 'react';
import { Text, View, Image, StyleSheet, Font, Svg, Path, G } from '@react-pdf/renderer';
import { getIcon } from "./Icons";

Font.register({
    family: "Montserrat",
    fonts: [
        { src: `${process.env.PUBLIC_URL}/fonts/Montserrat-VariableFont_wght.ttf` },
        { src: `${process.env.PUBLIC_URL}/fonts/Montserrat-Bold.ttf` }
    ]
});

const styles = StyleSheet.create({
    section: {
        marginLeft: 20,
        marginVertical: 17,
        flexDirection: "row",
        alignItems: "center",
        height: "56pt"
    },
    sectionSummary: {
        backgroundColor: "#7CCECE",
        borderTopLeftRadius: "50%",
        borderBottomLeftRadius: "50%"
    },
    sectionTitle: {
        fontWeight: "thin",
        fontSize: "12pt",
        textTransform: "uppercase",
        marginVertical: 2
    },
    sectionValue: {
        fontWeight: "extrabold",
        fontSize: "18pt"
    },
    sectionBody: {
        marginLeft: "30pt"
    },
    sectionBullet: {
        alignItems: "center",
        flexDirection: "row",
        backgroundColor: "#43C0C0",
        width: "56pt",
        height: "56pt",
        borderRadius: "50%"
    },
});

interface IPdfCalculationSection {
    icon: number;
    title: string;
    value: any;
    summary?: boolean;
}

const PdfCalculationSection: FC<IPdfCalculationSection> = ({ icon, title, value, summary = false }) => {
    const sectionStyles = (summary: boolean) => {
        if (summary) {
            return {
                ...styles.section,
                ...styles.sectionSummary
            };
        }

        return styles.section;
    }

    return (
        <View style={ sectionStyles(summary) }>
            <View style={styles.sectionBullet}>
                <Svg style={{ width: 56, height: 56 }} viewBox="0 0 56 56">
                    { getIcon(icon) }
                </Svg>
            </View>
            <View style={styles.sectionBody}>
                <Text style={styles.sectionTitle}>{ title }</Text>
                <Text style={styles.sectionValue}>{ value }</Text>
            </View>
        </View>
    );
};

export default PdfCalculationSection;
