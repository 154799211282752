import React, { FunctionComponent, useEffect, useState } from "react";
import Layout from "../components/layout/Layout";
import InverterBox from "../components/InverterBox";
import inverterBox from "../components/InverterBox";
import Slider from "../components/Slider";
import RoofGroundTypeBox from "../components/RoofGroundTypeBox";
import NumberInput from "../components/inputs/Number";
import Button from "../components/Button";
import RadioInput from "../components/inputs/Radio";
import Papa from "papaparse";
import { modulesSliderStepsCollection, energyStorageSliderStepsCollection } from "../data/SliderSteps";
import { roofGroundCollection, RoofGroundType } from "../data/RoofGround";
import { invertersCollection, InverterType} from "../data/Inverters";
import { pageData } from "../data/Page";
import { PRICING_COLS } from "../utils/Consts";
import {calculateCost, getPower, mapModulesCountToRowIndex} from "../utils/Utils";
import Currency, { formatPrice } from "../components/Currency";
import PolishPlural from "../utils/PluralFormatter";
import PdfCalculationDocument from "../components/PdfCalculation";
import { PDFDownloadLink } from "@react-pdf/renderer";
import ContactModal from "../components/ContactModal";

const Home: FunctionComponent = () => {
    const [pricing, setPricing] = useState([]);
    const [selectedInverter, setSelectedInverter] = useState<InverterType>(invertersCollection[0]);
    const [selectedRoofGroundType, setSelectedRoofGroundType] = useState<RoofGroundType>(roofGroundCollection[0]);
    const [numberOfModules, setNumberOfModules] = useState<number>(7);
    const [energyStorage, setEnergyStorage] = useState<number>(10);
    const [vatType, setVatType] = useState<string>('vat8');
    const [cableLength, setCableLength] = useState<number>(10);
    const [modalVisible, setModalVisible] = useState<boolean>(false);

    useEffect(() => {
        Papa.parse(`${process.env.PUBLIC_URL}/data/erato-cennik.csv`, {
            download: true,
            complete: function (input: any) {
                setPricing(input.data);
            }
        });
    }, []);

    const totalCost = calculateCost(
        selectedInverter,
        selectedRoofGroundType,
        energyStorage,
        cableLength,
        vatType,
        pricing[mapModulesCountToRowIndex(numberOfModules)]
    );

    const handleModulesOnChange = (value: any) => {
        setNumberOfModules(parseInt(value));
    }

    const handleEnergyStorageOnChange = (value: any) => {
        setEnergyStorage(parseInt(value));
    }

    const handleCableLengthOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const result = parseInt(event.target.value.replace(/\D/g, ''));

        if (isNaN(result)) {
            setCableLength(0);
        } else {
            if (result >= 1 && result <= 300) {
                setCableLength(result);
            }
        }
    }

  return (
    <Layout pageData={ pageData }>

        <h1 className="text-lg text-center mb-0">Oblicz koszt instalacji fotowoltaicznej</h1>
        <p className="text-center font-bold mt-10 mb-20">Moc instalacji: <span className="text-accentColor">{ getPower(numberOfModules, pricing) } kWp</span></p>

        <Slider
            handleOnChange={ handleModulesOnChange }
            identifier="sliderModules"
            title="Ilość modułów"
            valueTitle={ PolishPlural('moduł', 'moduły', 'modułów', numberOfModules) }
            steps={ modulesSliderStepsCollection }
            min={ 7 }
            max={ 105 }
            step={ 1 }
            value={ numberOfModules } />

        <hr className="w-full h-[2px] bg-inactiveColor mt-8 lg:mt-20 mb-0" />

        <p className="text-titleColor my-8">Wybór falownika</p>

        <section className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-[1.5rem] mb-8">
            {
                invertersCollection.map((inverter) => {
                    return <InverterBox handleOnClick={ () => setSelectedInverter(inverter) } key={ inverter.index } title={ inverter.title } selected={ inverter.index === selectedInverter.index } />
                })
            }
        </section>

        { selectedInverter.index === PRICING_COLS.INVERTER_2 ? (
                <Slider
                    handleOnChange={ handleEnergyStorageOnChange }
                    identifier="slider2"
                    title="Magazyn energii (kWh)"
                    valueTitle="kWh"
                    steps={ energyStorageSliderStepsCollection }
                    min={ 10 }
                    max={ 35 }
                    step={ 5 }
                    value={ energyStorage } />
            ) : <></>
        }

        <hr className="w-full h-[2px] bg-inactiveColor mt-8 lg:mt-20 mb-0" />

        <p className="text-titleColor my-8">Rodzaj pokrycia dachu/grunt</p>

        <section className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[1.5rem] mb-8">
            {
                roofGroundCollection.map((roofGroundType) => {
                    return <RoofGroundTypeBox handleOnClick={ () => setSelectedRoofGroundType(roofGroundType) } key={ roofGroundType.index } title={ roofGroundType.title } icon={ roofGroundType.icon } selected={ roofGroundType.index === selectedRoofGroundType.index } />
                })
            }
        </section>

        { selectedRoofGroundType.index === PRICING_COLS.GROUND_ROOF_TYPE_6 ? (
            <section className="w-full grid grid-cols-1 md:grid-cols-3 gap-[1.5rem] mb-8">
                <NumberInput handleOnChange={ handleCableLengthOnChange } title="Odległość instalacji od miejsca podłączenia (grunt):" value={ cableLength } unit="mb" />
            </section> )
            : <></>
        }


        <hr className="w-full h-[2px] bg-inactiveColor mt-8 sm:mt-20 mb-0" />

        <div className="text-titleColor my-8 w-full flex justify-start place-items-center justify-between">
            <span>Cena brutto z montażem:</span>
            <span className="text-md font-bold"><Currency price={ totalCost } /></span>
        </div>

        <div className="w-full flex flex-col md:flex-row md:justify-start md:place-items-center md:gap-8">
            <RadioInput handleOnClick={ () => setVatType('vat8') } defaultChecked={ true } name="vatType" title="Montaż dla potrzeb domu (VAT 8%)" value="vat8" />
            <RadioInput handleOnClick={ () => setVatType('vat23') } name="vatType" title="Montaż dla potrzeb firmy (VAT 23%)" value="vat23" />
        </div>

        <div className="w-full flex flex-col md:flex-row justify-center place-items-center gap-8 my-4">
            <PDFDownloadLink
                className="box-border text-2xs w-full md:w-auto font-extrabold text-center px-5 py-2.5 bg-white border border-solid border-black border-4 cursor-pointer text-black no-underline"
                document={ <PdfCalculationDocument cableLength={ cableLength } energyStorage={ energyStorage } inverter={ selectedInverter } roofGround={ selectedRoofGroundType } vatType={ vatType } modules={ numberOfModules } power={ getPower(numberOfModules, pricing) } totalCost={ formatPrice(totalCost) } /> }
                fileName="kalkulacja-kosztow.pdf">
                {({ blob, url, loading, error }) => ( 'Pobierz PDF' )}
            </PDFDownloadLink>
            <Button handleOnClick={ () => setModalVisible(true) } label="Prośba o kontakt" />
        </div>
        { modalVisible ?
            <ContactModal handleOnClick={ () => setModalVisible(false) } cableLength={ cableLength } energyStorage={ energyStorage } inverter={ selectedInverter } roofGround={ selectedRoofGroundType } vatType={ vatType } modules={ numberOfModules } power={ getPower(numberOfModules, pricing) } totalCost={ formatPrice(totalCost) } /> : <></>
        }
    </Layout>
  );
};

export default Home;
