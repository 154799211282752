import React, { FC } from "react";
import styled from 'styled-components';
import tw from "twin.macro";

const StyledButton = styled.button`
  ${tw`text-2xs w-full md:w-auto font-extrabold text-center px-5 py-2.5 bg-white border border-solid border-black border-4 cursor-pointer text-black font-body`}
`

interface InverterBoxProps {
    label: string;
    disabled?: boolean;
    handleOnClick?: React.MouseEventHandler;
}

const Button: FC<InverterBoxProps> = ({ label, disabled, handleOnClick }) => {
    return (
        <StyledButton onClick={ handleOnClick } disabled={ disabled }>
            { label }
        </StyledButton>
    );
};

export default Button;
