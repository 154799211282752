import React, { FC } from "react";
import styled from 'styled-components';
import tw from "twin.macro";

const ErrorContainer = styled.div`${tw`border-errorColor border-solid border-2 bg-errorLightColor text-errorColor rounded p-2 mb-8 text-sm`}`

type ErrorType = {
    errorMessage: string;
}

const RequestError: FC<ErrorType> = ({ errorMessage }) => {
  return <ErrorContainer><span>{ errorMessage }</span></ErrorContainer>;
};

export default RequestError;
